.unlock_btn
{
    background-color: #00BCD4 !important;
}
a[variant="subtle"]#swap-nav-link ,a[variant="subtle"]#pool-nav-link
{
    background-color: rgb(138, 203, 233)  !important;
    color:rgb(255, 255, 255);
  }
  body {
    background: linear-gradient( 112.25deg,rgb(224, 224, 224) 16.48%,rgb(224, 224, 224) 85.74%) fixed no-repeat;
  }
  #swap-page{
    background-color: rgb(255, 255, 255);
  }
  #swap-button{
    background-color: #8acbe9;
    color:rgb(255 255 255);
  }
  #swap-currency-input{
      color: rgb(255 255 255);
    }
    .open-currency-select-button{
      color: rgb(255 255 255);
    }
    .token-amount-input{
color:#0a0a0a ;
    }
  
  .card_dis{
    margin-top: 0%;
    position: relative;
    max-width: 50%;
    width: 100%;
    z-index: 5;
   
  }
  .dis_color{
    background: transparent;
     color: #8F8D8D;
  }
.dis_text{
    font-size: smaller;
}

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .card_dis{
        max-width: 100% !important;
    }
  }

 